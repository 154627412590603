<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <h6 class="text-h6">
        {{ $trans("add_new_stripe_product_title") }}
      </h6>
    </v-card-title>
    <v-divider />
    <v-form ref="form" @submit.prevent="handleAdd">
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="name"
                hide-details="auto"
                :rules="[rules.required, rules.maxChars(50)]"
                :label="$trans('product_name')"
                :hint="$trans('subscription_name_example')"
                persistent-hint
                outlined
                class="required-star"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text--secondary">
              {{ $trans("fields_required") }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn :disabled="isLoading" color="primary" text type="submit">
          {{ $trans("add") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";

export default {
  name: "AddStripeProductDialog",
  data() {
    return {
      isLoading: false,
      status: "active",
      name: null,
      rules: {
        required,
        maxChars,
      },
    };
  },
  methods: {
    ...mapActions({
      createStripeProduct: "stripe/createStripeProduct",
      fetchConnectProducts: "stripe/fetchConnectProducts",
    }),
    handleAdd() {
      if (this.$refs.form.validate()) {
        this.add();
      }
    },
    async add() {
      this.isLoading = true;
      this.$emit("loading", true);
      try {
        await this.createStripeProduct({ name: this.name });
        await this.fetchConnectProducts({ limit: 9999 });
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
